@font-face {
  font-family: 'Averta Std';
  font-weight: normal;
  font-style: normal;
  src: local('AvertaStd-Regular'),
    url(./assets/fonts/AvertaStd-Regular.woff) format('woff'),
    url(./assets/fonts/AvertaStd-Regular.eot?#iefix) format('embedded-opentype');
}

@font-face {
  font-family: 'Averta Std';
  font-style: normal;
  font-weight: 600;
  src: local('AvertaStd-Semibold'),
    url(./assets/fonts/AvertaStd-Semibold.woff) format('woff'),
    url(./assets/fonts/AvertaStd-Semibold.eot?#iefix) format('embedded-opentype');
}

@font-face {
  font-family: 'Averta Std';
  font-style: normal;
  font-weight: 700;
  src: local('AvertaStd-Bold'),
    url(./assets/fonts/AvertaStd-Bold.woff) format('woff'),
    url(./assets/fonts/AvertaStd-Bold.eot?#iefix) format('embedded-opentype');
}

@font-face {
  font-family: 'Averta Std';
  font-style: normal;
  font-weight: 800;
  src: local('AvertaStd-Extrabold'),
    url(./assets/fonts/AvertaStd-Extrabold.woff) format('woff'),
    url(./assets/fonts/AvertaStd-Extrabold.eot?#iefix) format('embedded-opentype');
}
